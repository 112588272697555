.backButton,
.menuButton {
    position: fixed;
    top: 10px;
    z-index: 99913;
    font-weight: bold;
    font-size: 1.3em;
    padding: 0;
}

.menuButton {
    cursor: pointer;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary);
}

.menuButton svg {
    height: 30px;
    width: 30px;
}

.menuButton svg path {
    stroke: none;
    fill: white;
}

.backButton {
    font-size: 1.5em;
    padding: 6px 13px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuButton {
    right: 10px;
}

.menu {
    padding-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 99912;

    position: fixed;
    top: 0;
    left: calc(100vw + 20px);
    right: calc(-80vw - 20px);
    bottom: 0;
    transition: left 400ms ease-out, right 400ms ease-out;

    background-color: var(--secondary);
}

.menu .thing svg {
    fill: var(--secondary);
    stroke: var(--secondary);
}

.menu .thing {
    color: var(--secondary);
}

.menuTouchOutsideSensor {
    z-index: 99911;
}

.menu:after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 80vw;
    left: -100vw;
    background-color: #e3e6e850;
    opacity: 0;
    transition: opacity 400ms ease-out;
}

.menu.show {
    left: 20vw;
    right: 0;
}

.menu.show:after {
    opacity: 1;
}

.menu__colorButtons {
    margin-top: 40px;
}

.menu__colorButtons button {
    margin-bottom: 10px;
}

.menu > div {
    position: relative;
    margin-bottom: 10px;
}

.menu > div .deleteButton {
    height: 2rem;
    width: 2rem;
}

.menu .links {
    display: grid;
    grid-template-columns: auto auto;
    gap: .5rem
}

.menu .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu a * {
    pointer-events: none; /* allow menu item click detection to close menu*/
}
