.App {
  text-align: center;

  --bg: #e3e6e8;
  --bg-secondary: #c8ced2;
  --contrast: black;
  --contrast-inverted: white;
  --contrast-inverted-half-opacity: rgba(255, 255, 255, .5);

  --primary: #268315;
  --secondary: #0f4d67;

  /* todo: add 80s sneaker mode with dem here colors */
  /*--primary: #31a11c;*/
  /*--secondary: rebeccapurple;*/


  --primary-half-opacity: #3e81c450;
  --primary-shadow: #2a2f38;

  --error: darkred;


  background-color: var(--bg);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

h1 {
  margin-top: 0;
}

.thing,
.thing .thing .thing {
  color: var(--contrast-inverted);
  background-color: var(--primary);
  padding: 10px 20px;
}


.thing .confirmButton svg {
  fill: var(--contrast-inverted);
}

.thing .confirmButton.disabled svg {
  fill: var(--contrast-inverted-half-opacity);
}


.rounded {
  border-radius: 5px;
}

.thing .thing {
  background-color: var(--contrast-inverted);
  color: var(--primary);
}

.thing .thing input {

  border: 1px solid var(--primary);
}

.thing .thing svg {
  fill: var(--primary);
}


.thing .thing .confirmButton svg {
  fill: var(--primary);
}

.thing .thing .confirmButton.disabled svg {
  fill: var(--primary-half-opacity);
}

.touchOutsideSensor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.page {
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 20px;
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 300px;
}


.addButton,
.deleteButton,
.graphButton {
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addButton {
  height: 70px;
  width: 70px;
  font-weight: bold;
  font-size: 4rem;
}

.deleteButton,
.graphButton {
  height: 40px;
  width: 40px;
}

.confirmButton,
.confirmButton svg {
  padding: 0;
  height: 80px;
  width: 80px;
}

.confirmButton svg {
  stroke: none;
}

.longPressButton {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}