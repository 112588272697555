.workout {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workout > h1 {
    color: #164070;
}

.workout .tricky.thing {
    background-color: #00000000;
}

.workout .xburgerMenu .tricky.thing .thing svg {
    fill: var(--secondary);
}

.workout .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    
    padding: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.workout .settingsButton path {
    stroke: none;
}

.workout .xburgerMenu {
    padding-top: 2rem;
}

.workout .xburgerMenu > p {
    opacity: 1;
    transition: opacity 150ms linear;
    transition-delay: 150ms;
}

.xburgerMenu:not(.show) > p {
    opacity: 0;
    transition: opacity 50ms linear;
    transition-delay: 0ms;
}

.workout .xburgerMenu,
.workout .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workout .xburgerMenu .types {
    max-height: calc(100vh - 10rem);
    overflow: auto;
}

.workout .xburgerMenu .types > div {
    margin: 0;
}

.workout .xburgerMenu .types > div:not(:first-child) {
    margin-top: 1rem;
}

.workout .xburgerMenu .heading {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 0;
}

.workout .modalContent button {
    margin: 1rem 0;
}


.workout .tricky.thing .modalContent button {

    color: dodgerblue;
    background-color: white;
}


.workout .buttonGroup {
    display: flex;
    gap: 1rem;
}

.workout .buttonGroup button {
    margin: 1rem;
}

.workout .sessions {
    width: 100vw;
    height: calc(100vh - 5rem);
    overflow-x: auto;
    overflow-y: visible;
}

.workout .sessions .addButton {
    margin: 1rem auto;
}

.session {
    position: relative;
    width: 90vw;
    margin: .5rem auto;
    padding: 1rem 1.5rem;
    color: white;
}

.editing.session {
    padding: 1rem .5rem;
}

.session .exerciseName {
    overflow-wrap: anywhere;
    text-align: left;
    max-width: calc(100% - 7rem);
}

.session.editing .exerciseName {
    max-width: calc(100% - 11rem);
}


.session.editing .exerciseName.withHistory {
    max-width: calc(100% - 10rem);
    border: 1px solid #ffffff50;
    padding: .35rem .5rem;
    margin: -.5rem;
    border-radius: 4px;
}


.session.editing {
    z-index: 2;
}

.session h3 {
    margin: 0;
}

.session .DateInput_input,
.session .SingleDatePickerInput,
.session .DateInput {
    border-radius: .5rem !important;
}

.session .SingleDatePicker {
    margin-bottom: 1rem;
}


.exercise .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.exercise.withMargins {
    margin: 1rem 0;
}

.exercise p {
    margin: 0;
}

.exercise input {
    width: 2rem;
    text-align: center;
    border-radius: .3rem;
}

.exercise input:first-child {
    width: 2.5rem;
}


.exercise .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.exercise .history {
    width: fit-content;
    margin-left: auto;
}

.exercise .history .previousSession {
    text-align: right;
    padding: .25rem 0;
}

.exercise .history .previousSession span {
    display: inline-block;;
    width: 32px;
    text-align: center;
    margin: 0 2px 0 -2px;
}
.exercise .history .previousSession span:first-child {
    width: 40px;
}

.exercise .history .previousSession:nth-child(2) {
    opacity: .6;
}
.exercise .history .previousSession:nth-child(3) {
    opacity: .4;
}
.exercise .history .previousSession:nth-child(4) {
    opacity: .2;
}
