body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

button {
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

input {
  border: none;
  padding: 5px;
  outline: none;
  font-size: 1rem;
}

input,
button {
  color: var(--contrast);
}

svg,
svg line {
  fill: var(--contrast);
}

.thing > svg,
.thing > svg line,
.thing .thing .thing > svg,
.thing .thing .thing > svg line {
  fill: var(--contrast-inverted);
  stroke: var(--contrast-inverted);
}

.thing .thing > svg,
.thing .thing > svg line {
  fill: var(--contrast);
  stroke: var(--contrast);
}

a {
  text-decoration: none;
}

button {
  -webkit-tap-highlight-color: transparent;

}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}