
.draggableListItem {
    position: relative;
    /*margin-bottom: 1rem;*/
    margin-bottom: .5rem;

    background-color: var(--primary);
    /*background-color: dodgerblue;*/
    border-radius: .5rem;
    /*padding: .2rem .5rem;*/
    padding: .5rem 1rem;

}

.draggableListItem.dragging {
    transform: translate(0, -10px);

    -webkit-box-shadow: 0 10px 5px -4px #33333350;
    -moz-box-shadow: 0 10px 5px -4px #33333350;
    box-shadow: 0 10px 5px -4px #33333350;

    padding: .4rem 1rem .8rem 1rem;


    /*background-color: hotpink!important;*/
}