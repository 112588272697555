.week {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: max(50vw, 230px);
}

.week .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.week .expansionWrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 200ms linear;
}

.week .expansionWrapper:not(.open) {
    overflow: hidden;
}

.expanded.week .expansionWrapper {
    grid-template-rows: 1fr;
}
.week .expansion {
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.week .header .time {
    padding-top: .3rem;
}

.week__quantity {
    font-size: 2rem;
    margin-left: 10px;
}

.datum {
    margin-top: 10px;
    font-size: 1.5rem;
    z-index: 2;
}

.datum__date {
    font-size: 1rem;
    margin-right: 10px;
}

.editCountDatum {
    margin-top: 10px;
    z-index: 3;
}

.editCountDatum__quantity {
    font-size: 2rem;
    margin: 10px 0;
}

.editCountDatum__counter .incrementButton,
.editCountDatum__counter .decrementButton {
    margin: 0 auto;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editCountDatum__counter .incrementButton .arrow,
.editCountDatum__counter .decrementButton .arrow {
    background-color: var(--contrast-inverted);
    height: 40px;
    width: 40px;
}

.editCountDatum__counter .decrementButton.disabled .arrow {
    background-color: var(--contrast-inverted-half-opacity);
}

.thing .thing .editCountDatum__counter .incrementButton .arrow,
.thing .thing .editCountDatum__counter .decrementButton .arrow {
    background-color: var(--primary);
}

.thing .thing .editCountDatum__counter .decrementButton.disabled .arrow {
    background-color: var(--primary-half-opacity);
}

.editCountDatum__counter .incrementButton .arrow {
    margin-top: 10px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.editCountDatum__counter .decrementButton .arrow {
    margin-bottom: 10px;
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
}

.count .graphButton {
    position: fixed;
    top: .5rem;
    left: .5rem;
}