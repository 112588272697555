.summary {
    padding-bottom: 0;
}

.summary h1 {
    margin-bottom: 0;
}

.summary .content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hotpink!important;
}

.summary .closeButton {
    position: absolute;
    top: .5rem;
    left: .5rem;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 100%;
}

.graphWrapper {
}

.graph {
    position: relative;
}

.scrollContainer {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .2rem;
    padding: 1rem 0 2rem 0;

    max-height: calc(100vh - 5rem);
    overflow: auto;
}

.graph .scrollFade {
    position: absolute;
    left: 0;
    right: 0;
    height: 2rem;
    z-index: 2;
}

.graph .scrollFade.top {
    top: 0;
    background: linear-gradient(#e3e6e8, #e3e6e8, #ffffff00);
}

.graph .scrollFade.bottom {
    bottom: 0;
    background: linear-gradient(#ffffff00, #e3e6e8, #e3e6e8);
}

.graph .monthLabel,
.graph .yearLabel {
    text-align: left;
}

.graph .monthLabel {
    font-weight: bold;
    margin-top: 1rem;
}

.graph .bar {
    position: relative;
    margin: 0 .5rem;
}

.graph .bar .value {
    height: 100%;
    border-radius: .2rem;
}
.graph .bar .label {
    position: absolute;
    top: 0;
    left: .5rem;
    bottom: 0;
}

.graph .bar:not(.empty) .label {
    color: white;
}
