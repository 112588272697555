
.SingleDatePicker {
    z-index: 5;
}

.DateInput_input {
    width: 100%;
}

.DateInput_input {
    text-align: center;
}

.SingleDatePicker_picker {
    left: -93px !important;
}
.DateInput_fang {
    left: 56px;
}