
.modal {
    position: fixed;
    top: 30vh;
    left: 20px;
    right: 20px;

    z-index: 2001;
}

.modalContent {
    position: relative;
    z-index: 2;
}

.modalBg,
.thing .thing .modalBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: var(--contrast-inverted-half-opacity);
}

.thing .modalBg {
    background-color: var(--primary-half-opacity);
}
