
.newCount .thing {
    margin-bottom: 20px;
}

.newCount label,
.newCount input {
    display: block;
}

.newCount input {
    margin: 0 auto 20px auto;

}

.newCount__error {
    font-size: 1.2rem;
    background-color: var(--error);
    color: var(--contrast-inverted);
    padding: 10px;
}

.newCount__error span {
    display: block;
}
